/* custom.css */
.rce-mbox {
    background-color: #281D4D;
  }
  
  .rce-mbox-left {
    --message-bg-color: #f0f0f0; /* Left message box color */
  }
  
  .rce-mbox-right {
    --message-bg-color: #e0ffe0; /* Right message box color */
  }
  .custom-message-left .rce-mbox-left-notch {
    background-color: #281D4D;
  }
  .custom-message-right .rce-mbox-right-notch {
    border-left-color: #bbf7d0; /* Same color as bg-green-200 */
  }