@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 700;
  src: local("AvenirNext-Bold-01"),
    url("./fonts/AvenirNext-Bold-01.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 700;
  font-style: italic;
  src: local("AvenirNext-BoldItalic-02"),
    url("./fonts/AvenirNext-BoldItalic-02.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 600;
  src: local("AvenirNext-DemiBold-03"),
    url("./fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 600;
  font-style: italic;
  src: local("AvenirNext-DemiBoldItalic-04"),
    url("./fonts/AvenirNext-DemiBoldItalic-04.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 900;
  src: local("AvenirNext-Heavy-09"),
    url("./fonts/AvenirNext-Heavy-09.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 900;
  font-style: italic;
  src: local("AvenirNext-HeavyItalic-10"),
    url("./fonts/AvenirNext-HeavyItalic-10.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 400;
  font-style: italic;
  src: local("AvenirNext-Italic-05"),
    url("./fonts/AvenirNext-Italic-05.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 500;
  src: local("AvenirNext-Medium-06"),
    url("./fonts/AvenirNext-Medium-06.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 500;
  font-style: italic;
  src: local("AvenirNext-MediumItalic-07"),
    url("./fonts/AvenirNext-MediumItalic-07.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 400;
  src: local("AvenirNext-Regular-08"),
    url("./fonts/AvenirNext-Regular-08.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 200;
  src: local("AvenirNext-UltraLight-11"),
    url("./fonts/AvenirNext-UltraLight-11.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 200;
  font-style: italic;
  src: local("AvenirNext-UltraLightItalic-12"),
    url("./fonts/AvenirNext-UltraLightItalic-12.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "AvenirNext", sans-serif;
  text-transform: inherit;
  font-style: normal !important;
  font-size: 14px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jtable-fix { border-width: 2px !important; }