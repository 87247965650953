@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 700;
  src: local("AvenirNext-Bold-01"),
    url("./fonts/AvenirNext-Bold-01.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 700;
  font-style: italic;
  src: local("AvenirNext-BoldItalic-02"),
    url("./fonts/AvenirNext-BoldItalic-02.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 600;
  src: local("AvenirNext-DemiBold-03"),
    url("./fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 600;
  font-style: italic;
  src: local("AvenirNext-DemiBoldItalic-04"),
    url("./fonts/AvenirNext-DemiBoldItalic-04.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 900;
  src: local("AvenirNext-Heavy-09"),
    url("./fonts/AvenirNext-Heavy-09.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 900;
  font-style: italic;
  src: local("AvenirNext-HeavyItalic-10"),
    url("./fonts/AvenirNext-HeavyItalic-10.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 400;
  font-style: italic;
  src: local("AvenirNext-Italic-05"),
    url("./fonts/AvenirNext-Italic-05.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 500;
  src: local("AvenirNext-Medium-06"),
    url("./fonts/AvenirNext-Medium-06.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 500;
  font-style: italic;
  src: local("AvenirNext-MediumItalic-07"),
    url("./fonts/AvenirNext-MediumItalic-07.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 400;
  src: local("AvenirNext-Regular-08"),
    url("./fonts/AvenirNext-Regular-08.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 200;
  src: local("AvenirNext-UltraLight-11"),
    url("./fonts/AvenirNext-UltraLight-11.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";   /*Can be any text*/
  font-weight: 200;
  font-style: italic;
  src: local("AvenirNext-UltraLightItalic-12"),
    url("./fonts/AvenirNext-UltraLightItalic-12.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #181818; /* Updated background color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
